// theme.ts
import { DefaultTheme } from "styled-components";

export const defaultTheme: DefaultTheme = {
  palette: {
    primary: "#9891E8",
    secondary: "#6C63FF",
    background: {
      main: "#6a5be2",
      light: "#9891e8",
      dark: "#5448b5",
      white: "#eeeefb",
    }
  },
};
