import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    font-family: Baloo, Verdana, Geneva, Tahoma, sans-serif;
  }
  
  body {
    background: ${({ theme }) => theme.palette.background.main};
    margin: 0;
  }

  #root{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
`;

export default GlobalStyle;
