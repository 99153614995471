import { createBrowserRouter } from "react-router-dom";
import { InputField } from "../components/input-field/InputField";
import MainLayout from "../layouts/MainLayout";
import { LoginPage } from "../pages/LoginPage";

const router = createBrowserRouter([
  {
    element: <MainLayout />,
    children: [
      {
        path: "/input",
        element: (
          <InputField
            placeHolder={""}
            onChange={function (stringValue: string): void {
              throw new Error("Function not implemented.");
            }}
          />
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
]);

export default router;
