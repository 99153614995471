import React, { useState } from "react";
import styled from "styled-components";
import {ReactComponent as HomeIcon} from "./assets/home.svg";
import {ReactComponent as LeaderboardIcon} from "./assets/leaderboard.svg";
import {ReactComponent as ProfileIcon} from "./assets/profile.svg";
import {ReactComponent as SettingsIcon} from "./assets/settings.svg";
import {ReactComponent as NavbarBackground} from "./assets/background.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { NavbarRoutes } from "./NavbarRoutes";

const FullWidthWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center; 
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 600px;
  align-items: end;
`;

const BackgroundImage = styled.div<{ pixelOffset: number }>`
  position: absolute;
  bottom: 0;
  height: 94px;
  transition: transform 0.5s ease-in-out;
  overflow: hidden;
  transform: translate(${({ pixelOffset }) => pixelOffset}px);
`;

const Ball = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  z-index: 1000;
  margin-bottom: ${({ isActive }) => (isActive ? "3.5rem" : "1rem")};
  transition: margin-bottom 0.5s ease-in-out;
  cursor: pointer;
  fill: ${({theme}) => theme.palette.background.main};
`; 

export const Navbar: React.FC = () => {
  const [selected, setSelected] = useState<NavbarRoutes>(NavbarRoutes.PlayGame);
  const { width } = useWindowDimensions();
  const menuWidth = Math.min(width, 600);

  /**
   * Gets the pixel offset for the background image form the middle of the screen/navbar
   * according to the selected route
   */
  const calcPosition = () => {
    switch (selected) {
      case NavbarRoutes.PlayGame:
        return -0.375 * menuWidth;
      case NavbarRoutes.Leaderboard:
        return -0.125 * menuWidth;
      case NavbarRoutes.Games:
        return 0.125 * menuWidth;
      case NavbarRoutes.Settings:
        return 0.375 * menuWidth;
      default:
        return 0;
    }
  };

  return (
    <FullWidthWrapper>
      <BackgroundImage pixelOffset={calcPosition()}>
        <NavbarBackground fill="#fff" />
      </BackgroundImage>
      <ButtonWrapper>
        <Ball
          onClick={() => setSelected(NavbarRoutes.PlayGame)}
          isActive={selected === NavbarRoutes.PlayGame}
        >
          <HomeIcon />
        </Ball>
        <Ball
          onClick={() => setSelected(NavbarRoutes.Leaderboard)}
          isActive={selected === NavbarRoutes.Leaderboard}
        >
          <LeaderboardIcon />
        </Ball>
        <Ball
          onClick={() => setSelected(NavbarRoutes.Games)}
          isActive={selected === NavbarRoutes.Games}
        >
          <ProfileIcon fillRule="evenodd"/>
        </Ball>
        <Ball
          onClick={() => setSelected(NavbarRoutes.Settings)}
          isActive={selected === NavbarRoutes.Settings}
        >
          <SettingsIcon />
        </Ball>
      </ButtonWrapper>
    </FullWidthWrapper>
  );
};
