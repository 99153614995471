import React from "react";
import styled from "styled-components";
import { Button } from "../components/button/Button";
import { ButtonVariant } from "../components/button/ButtonVariant";
import FlexCol from "../components/common/FlexCol";
import { InputField } from "../components/input-field/InputField";
import Party from "../stories/assets/party.png";

const Wrapper = styled(FlexCol)`
  background: #6a5be2;
  gap: 1rem;
`;

const LoginForm = styled(FlexCol)`
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  background: ${({ theme }) => theme.palette.background.white};
  gap: 1rem;
  padding: 1rem 1rem 3rem 1rem;
  border-radius: 48px 48px 0 0;
`;

const HeaderText = styled.h1`
  color: white;
`;

export const LoginPage: React.FC = () => {
  return (
    <Wrapper>
      <HeaderText>FOOBLE</HeaderText>
      <img src={Party} alt="" />
      <LoginForm>
        <h1 style={{ margin: "none", textAlign: "center" }}>Welcome</h1>
        <InputField placeHolder="username" onChange={() => console.log()} />
        <InputField
          placeHolder="password"
          isPassword
          onChange={() => console.log()}
        />
        <Button label="Login" />
        <Button variant={ButtonVariant.Secondary} label="Create new account" />
      </LoginForm>
    </Wrapper>
  );
};
