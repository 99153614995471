import React from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(195, 195, 215, 0.34);
  border-radius: 30px;
  border: none;
  outline: none;
  padding: 0.5rem 2rem;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.04em;
  color: black;
  ::placeholder {
    color: #aaa9b6;
  }
`;

interface InputFieldProps {
  placeHolder: string;
  isPassword?: boolean;
  onChange: (stringValue: string) => void;
}

export const InputField: React.FC<InputFieldProps> = ({
  placeHolder = "Username",
  isPassword = false,
  onChange,
}) => {
  return (
    <StyledInput
      type={isPassword ? "password" : "text"}
      placeholder={placeHolder}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};
