import styled from "styled-components";
import { ButtonVariant } from "./ButtonVariant";

const StyledButton = styled.button<{ variant: ButtonVariant }>`
  background: ${({ variant, theme }) =>
    variant === ButtonVariant.Secondary
      ? theme.palette.secondary
      : theme.palette.primary}; // Default to primary
  border-radius: 30px;
  width: 100%;
  border: none;
  padding: 0.5rem 2rem;
  color: white;
  margin: 0;
  font-size: 24px;
  letter-spacing: 0.04em;

  color: #ffffff;

  :hover {
    cursor: pointer;
  }
`;

interface ButtonProps {
  variant?: ButtonVariant;
  backgroundColor?: string;
  label: string;
  onClick?: () => void;
}

export const Button = ({
  variant = ButtonVariant.Primary,
  backgroundColor,
  label,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton variant={variant} {...props}>
      {label}
    </StyledButton>
  );
};
